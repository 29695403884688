import React, {useEffect, useState} from 'react';
import { useSearchParams } from "react-router-dom";
import { Nav, Icon } from 'rsuite';
import AdminBrandManager from './AdminBrandManager'
import AdminUserManager from './AdminUserManager'

const AdminArea = (props) => {
  let [searchParams, setSearchParams] = useSearchParams();
  let [active, setActive] = useState(searchParams.get("tab") || 'users');

  const handleSelect = (eventKey) => {
    setActive(eventKey)
    setSearchParams({ tab: eventKey });
  }

  return (
    <>
    <div style={{ maxWidth: '200px', textAlign:'center', margin:'0 auto', marginBottom: '20px' }}>
      <Nav justified appearance="subtle" activeKey={active} onSelect={handleSelect}>
        <Nav.Item eventKey="users" icon={<Icon icon="user" />}>Users</Nav.Item>
        <Nav.Item eventKey="brands" icon={<Icon icon="image" />}>Brands</Nav.Item>
      </Nav>
    </div>
    { active === 'brands' &&
      <AdminBrandManager />
    }
    { active === 'users' &&
      <AdminUserManager />
    }
    
    </>
  )

}

export default AdminArea;