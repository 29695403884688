import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import firebase, { fbAuthChange } from './firebase.js';
import { useAuthState } from 'react-firebase-hooks/auth';
import Unauthorised from './components/Unauthorised.jsx';
import logo from './assets/images/imagebank-logo.svg';
import spinner from './assets/images/spinner.gif';
import AdminArea from './components/admin/AdminArea';
import './App.css'

function App() {
  const [user, loading, error] = useAuthState(firebase.auth());
  const [display, setDisplay] = useState(false);
  const [ibUser, setIbUser] = useState('');
  const navigate = useNavigate();

  const hide = async (ms, role) => {
    await new Promise(r => setTimeout(r, ms))
    setDisplay(false)
  }

  useEffect(()=> {
    const setupUser = async (user) => {
      const userData = await fbAuthChange(user);
      setIbUser(userData);
      setDisplay(true);
      //hide(1000, userData?.role)
      if(userData?.role === 'viewer') navigate(`/${userData?.brand}`)
    }  
    if(user) {
      setupUser(user);
    }
    return () => {
      // component unmounted
    }
  }, [user, loading, navigate])

  const name = user?.displayName ? user?.displayName.split(" ")[0] : user?.email

  return (
    <div id="adminPage" className="pb-5" style={{ backgroundColor: '#fff' }}>
      { (display || !user) && <div className={`coverscree loading-${loading || ibUser?.role !== "admin"}`}></div> }
      <div className="container">
        <header className="d-flex flex-wrap justify-content-center py-3 align-items-center">
          <img src={logo} alt='Good Humans ImageBank logo' className="logo mb-3"></img>
        </header>
        { loading  &&
          <img src={spinner} alt="loading spinner" className="mx-auto d-block my-3" />
        }  
        { (!loading && ibUser?.role === "admin") && 
          <AdminArea />
        }
        { (!loading && !user) &&
          <Unauthorised authLoaded={true} />
        }
        { (display && ibUser?.role === "unauthorised") &&
          <p style={{ textAlign: 'center'}}>You'll need to be let in by a Good Human before accessing this page</p>
        }
      </div>
    </div>
  );

}


export default App;
