import React, { useEffect, useState } from 'react'
import { Table, Alert, IconButton, Icon } from 'rsuite';
import { imagebankRef } from './../../firebase';
import NewBrandForm from './NewBrandForm';
const { Column, HeaderCell, Cell, } = Table;

const AdminBrandManager = (props) => {
  const [brands, setBrands] = useState([]);
  const [addingNew, setAddingNew] = useState(false);

  const ImageCell = ({ rowData, dataKey, ...props }) => (
    <Cell {...props}>
      <img src={rowData[dataKey]} width="50" />
    </Cell>
  );

  
  const addBrand = () => {
    setAddingNew(!addingNew);
  }
  
  const saveBrand = async (brand) => {
    if(!brand.id) {
      // if new brand, get firebase ref as ID
      brand.id = imagebankRef.child('brands_2022').push().key;
      if(!brand.parent) brand.parent = "";
      setBrands([...brands, brand])
    } else {
      // if existing brand, replace it in our brands array
      setBrands(brands.map(obj => brand.id === obj.id ? brand : obj));
    }
    console.log(brand);
    imagebankRef.child(`brands_2022/${brand.id}`).set(brand).then(() => {
      Alert.success('Data saved successfully.', 5000);
      setAddingNew(false);
    }).catch((error) => {
      Alert.error(`Data save issue. ${error}`, 5000);
    });
  }

  const deleteBrand = (id) => {
    imagebankRef.child(`brands_2022/${id}`).remove().then(() => {
      Alert.success('Brand removed.', 3000);
      setBrands(
        brands.filter(obj => obj.id !== id)
      )
    }).catch((error) => {
      Alert.error(`Data removal issue. ${error}`, 3000);
    });
  }


  useEffect(() => {

    const fetchBrands = async () => { 
      let brands = [];
      const brandSnapshot = await imagebankRef.child(`brands_2022/`).once("value")
      // Get JSON object from Firebase
      const brandsObj = brandSnapshot.val();
      // Convert into parent + children array to use in tree table
      let treeObj = Object.values(brandsObj).reduce((acc, obj) => {
        let parent = obj.parent;
        if(parent === "") {
          // If the current object IS a parent we should add this object to the acc
          // - without overwriting any children we've already added
          acc[obj.label] = { ...obj, ...acc[obj.label] };
          // if we haven't added any children yet, let's create the empty array
          if(!acc[obj.label].children) acc[obj.label].children = []
          return acc;
        } else {
          // If the current object is a child and we have defined the parent object already
          if(acc[parent]) {
            acc[parent].children.push(obj);
          } else {
            // If we still need to define the children object
            acc[parent] = { 'children': [obj] }
          }
          return acc;
        }
      }, {})
      
      Object.values(treeObj).map(item => {
        // to fix bug with new brands parent logo, if only 1 child brand, use the logo of child
        if(item.children.length == 1) item.logo = item.children[0].logo
      })
      setBrands(Object.values(treeObj))
    }

    fetchBrands();

    return () => {
      // component unmounted
    }
  }, [])

  return (
    <>
      <div className="flex text-center mb-3">
        <IconButton icon={<Icon icon={addingNew ? 'minus' : 'plus'} />} className="mb-2" placement="left" onClick={addBrand}>
          { addingNew ? 'Cancel' : 'Add Brand' }
        </IconButton>

        { addingNew && 
          <div style={{ width: '650px', margin: "0 auto" }} >
            <NewBrandForm addBrand={saveBrand} brands={brands} />
          </div>
        }
      </div>

      <Table
        isTree
        defaultExpandAllRows
        rowKey="id"
        minHeight={600}
        autoHeight
        hover={false}
        data={brands}
        rowHeight={65}
        width={650}
        style={{ margin: '0 auto' }}
      >
        <Column widhth={150}>
          <HeaderCell>Logo</HeaderCell>
          <ImageCell dataKey="logo" />
        </Column>

        <Column width={150}>
          <HeaderCell>Brand Name</HeaderCell>
          <Cell dataKey="name" />
        </Column>

        <Column width={140}>
          <HeaderCell>Label</HeaderCell>
          <Cell dataKey="label" />
        </Column>

        <Column width={70}>
          <HeaderCell>Hidden</HeaderCell>
          <Cell dataKey="hidden" />
        </Column>

        <Column width={140}>
          <HeaderCell>Parent</HeaderCell>
          <Cell>
              {rowData => {
                return rowData.parent ? (
                  <span>{rowData.parent}</span>
                ) : (
                  <a href={`/${rowData.label}`}> Go to ImageBank </a>
                );
              }}
          </Cell>
        </Column>

      </Table>
    </>
  )
}

export default AdminBrandManager;