import React from 'react';
import { imagebankRef } from '../../firebase.js';
import { Table, Alert, SelectPicker, Icon, IconButton, InputPicker } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';

const { Column, HeaderCell, Cell } = Table;

const EditCell = ({ rowData, dataKey, onChange, ...props }) => {
  const selectData = [{
    label: 'Unauthorised',
    value: 'unauthorised'
  },{
    label: 'Viewer',
    value: 'viewer'
  },{
    label: 'Admin',
    value: 'admin'
  }]
  const handleChange = (value) => {
    if(onChange) onChange(rowData.uid, dataKey, value);
  }
  return (
    <Cell {...props} className=''>
      <SelectPicker
        cleanable={false}
        style={{marginTop: '-7px' }}
        value={rowData[dataKey]}
        onChange={handleChange}
        data={selectData}
      />
    </Cell>
  );
};

const EditBrandCell = ({ rowData, dataKey, onChange, brandData, ...props }) => {
  const handleChange = (value) => {
    if(onChange) onChange(rowData.uid, dataKey, value);
  }
  return (
    <Cell {...props} className=''>
      { rowData.role !== 'admin' && 
        <SelectPicker
          cleanable={true}
          style={{marginTop: '-7px' }}
          value={rowData[dataKey]}
          onChange={handleChange}
          data={brandData}
        />
      }
    </Cell>
  );
};

const DeleteCell = ({ rowData, dataKey, handleDelete, ...props }) => {
  return (
    <Cell {...props} style={{ padding: '7px 10px' }}>
      <IconButton
        size="sm"
        onClick={() => handleDelete(rowData)}
        icon={<Icon icon="trash-o" />}
      />
    </Cell>
  );
};

export default class UserManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      brandSelectData: [],
      isFetching: false,
      show: false,
      isSaving: false,
      sortColumn: 'last_login',
      sortType: 'desc',
      filter: {
        role: '',
        brand: ''
      }
    };
    this.handleSortColumn = this.handleSortColumn.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.selectRoleChange = this.selectRoleChange.bind(this);
    this.selectBrandChange = this.selectBrandChange.bind(this);
  }

  componentDidMount() {
    if(this.state.users.length == 0) {
      this.fetchUsers();
    }
    this.fetchBrands();
  }

  fetchUsers() {
    this.setState({...this.state, isFetching: true});
    imagebankRef.child(`users/`).once("value", snapshot => {
      if (snapshot.exists()) {
        const usersObj = snapshot.val();
        const usersArray = Object.values(usersObj);
        const options = { year: "numeric", month: "long", day: "numeric" }
        usersArray.forEach((item) => {
          item.created_at = new Date(item.created_at).toLocaleDateString(undefined, options)
          item.last_login = new Date(item.last_login).toLocaleDateString(undefined, options)
        });
        this.setState({...this.state, users: usersArray, isFetching: false});
      } else {
        console.log('no users :(');
      }
    });
  }

  async fetchBrands() {
    const brandSnapshot = await imagebankRef.child(`brands_2022/`).once("value")
      // Get JSON object from Firebase
    const brandsObj = brandSnapshot.val();
    const brands = Object.values(brandsObj);
    const formattedBrands = {}; 
    brands.map(e => {
      if(e.parent == "") formattedBrands[e.label] = { 'label': e.label, 'value': e.label };
    })
    this.setState({ brandSelectData: Object.values(formattedBrands) })
  }

  getData() {
    const { users, sortColumn, sortType, filter } = this.state;
    
    const filteredUsers = users.filter(user => (
      (filter.role && filter.role !== user.role ? false : true) &&
      (filter.brand && filter.brand !== user.brand ? false : true)
    ))
    if (sortColumn && sortType) {
      return filteredUsers.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if(sortColumn === 'last_login' || sortColumn === 'created_at') {
          x = Date.parse(x);
          y = Date.parse(y);
        } else {
          x = x.charCodeAt();
          y = y.charCodeAt();
        }
        if (sortType === 'asc') {
          return x - y;
        } else {
          return y - x;
        }
      });
    }
    return filteredUsers;
  }


  handleSortColumn(sortColumn, sortType) {
    this.setState({
      sortColumn,
      sortType
    });
  }

  handleChange(uid, key, value) {
    this.setState({...this.state, isSaving: true});
    const nextData = Object.assign([], this.state.users);
    let userData = nextData.find(item => item.uid === uid);
    userData[key] = value;
    imagebankRef.child(`users/${uid}`).update(userData).then(() => {
      Alert.success('Data saved successfully.', 5000);
      this.setState({...this.state, isSaving: false});
    }).catch((error) => {
      Alert.error(`Data save issue. ${error}`, 5000);
      this.setState({...this.state, isSaving: false});
    });
  };

  handleDelete(user) {
    if (!window.confirm(`Are you sure you want to delete ${user.email} from the database?`)) return;
    this.setState({...this.state, isSaving: true});
    imagebankRef.child(`users/${user.uid}`).remove().then(() => {
      Alert.success('User removed.', 3000);
      const newUserArray = this.state.users.filter(item => item.email !== user.email)
      this.setState({...this.state, isSaving: false, users: newUserArray });
    }).catch((error) => {
      Alert.error(`Data removal issue. ${error}`, 3000);
      this.setState({...this.state, isSaving: false});
    });
  }

  prepareRoleFilter() {
    const users = this.state.users;
    const data = {};
    users.map(user => {
      data[user.role] = { label: user.role, value: user.role }
    })
    return Object.values(data);
  }

  prepareBrandFilter() {
    const users = this.state.users;
    const data = {};
    users.map(user => {
      if(!user.brand) return;
      data[user.brand] = { label: user.brand, value: user.brand }
    })
    return Object.values(data);
  }

  selectRoleChange(selected) {
    let filter = this.state.filter;
    filter.role = selected;
    this.setState({ filter });
  }

  selectBrandChange(selected) {
    let filter = this.state.filter;
    filter.brand = selected;
    this.setState({ filter });
  }

  render() {
    return (
      <div className="">
        <div className="d-flex mb-4">
          <div className="mr-3">
            <p className="mb-1">User Role</p>
            <InputPicker onChange={this.selectRoleChange} data={this.prepareRoleFilter()} style={{ width: 200 }}  />
          </div>
          <div>
            <p className="mb-1">Brand</p>
            <InputPicker onChange={this.selectBrandChange} data={this.prepareBrandFilter()} style={{ width: 200 }}  />
          </div>
        </div>
        <Table
          data={this.getData()}
          sortColumn={this.state.sortColumn}
          sortType={this.state.sortType}
          onSortColumn={this.handleSortColumn}
          loading={this.state.isSaving || this.state.isFetching}
          hover={false}
          autoHeight
          affixHeader
          affixHorizontalScrollbar
          bordered
        >

          <Column flexGrow={3} sortable minWidth={280} fixed>
            <HeaderCell>Email</HeaderCell>
            <Cell dataKey="email" />
          </Column>

          <Column flexGrow={2} minWidth={160}>
            <HeaderCell>Name</HeaderCell>
            <Cell dataKey="name" />
          </Column>

          <Column flexGrow={2} sortable minWidth={150}>
            <HeaderCell>Role</HeaderCell>
            <EditCell dataKey="role" onChange={this.handleChange} />
          </Column>

          <Column flexGrow={2}  minWidth={150}>
            <HeaderCell>Brand</HeaderCell>
            <EditBrandCell dataKey="brand" onChange={this.handleChange} brandData={this.state.brandSelectData} />
          </Column>

          <Column flexGrow={2} sortable minWidth={160}>
            <HeaderCell>Last Login</HeaderCell>
            <Cell dataKey="last_login" />
          </Column>

          <Column flexGrow={2} sortable minWidth={160}>
            <HeaderCell>Created At</HeaderCell>
            <Cell dataKey="created_at" />
          </Column>

          <Column minWidth={20} fixed="right" align="center">
            <HeaderCell>Remove</HeaderCell>
            <DeleteCell handleDelete={this.handleDelete} />
          </Column>

        </Table>

      </div>
    )
  }
}
