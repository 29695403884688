import firebase from 'firebase/app'
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/database';
import { Alert } from 'rsuite';

const config = {
  apiKey: "AIzaSyBU6-zU6poDqcBiyg4poi9y1BPno-YcAPc",
  authDomain: "good-humans-timesheet.firebaseapp.com",
  databaseURL: "https://good-humans-timesheet.firebaseio.com",
  projectId: "good-humans-timesheet",
  storageBucket: "good-humans-timesheet.appspot.com",
  appId: "1:677274292278:web:33c75e563da4e347"
};
firebase.initializeApp(config);

//export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
export const storageRef = firebase.storage().ref().child('ImageBank');
export const firebaseDatabase = firebase.database();
export const imagebankRef = firebaseDatabase.ref('/imagebank/');

export const fbAuthChange = async (user, parentBrand = "") => {
  let userData; //will be used for our custom user data
  const snapshot = await imagebankRef.child(`users/${user.uid}`).once("value")
  if (snapshot.exists()) {
    userData = snapshot.val();
    userData.last_login = new Date().toISOString();
    updateFirebaseUser(userData);
  } else {
    userData = await addFirebaseUser(user, parentBrand);
  }
  return userData;
}

const addFirebaseUser = async (user, parentBrand = "") => {
  const userId = user.uid;
  let adminDomains = ['goodhumans.co.uk', 'the-honeytrap.com'];
  // check is users is in database with a read function
  const userDomain = user.email.split('@')[1];
  const role = adminDomains.indexOf(userDomain) !== -1 ? 'viewer' : 'unauthorised';
  const userData = {
    uid: userId,
    name: user.displayName,
    email: user.email,
    role: role,
    created_at: new Date().toISOString(),
    last_login: new Date().toISOString(),
    brand: parentBrand
  }
  console.log(userData);
  if(user.email.indexOf('froggingbob') == -1) sendEmailToGH(userData);
  //console.log(userData);
  return imagebankRef.child(`users/${userId}`).set(userData,
    function(err) {
      if(err) {
        alert("Couldn't save user!");
      }
      console.log('added user to firebase');
      return userData;
    });
}

const updateFirebaseUser = (user) => {
  return imagebankRef.child(`users/${user.uid}`).update(user);
}

const sendEmailToGH = (user) => {
  // use cloud function and this tutorial: https://medium.com/@edigleyssonsilva/cloud-functions-for-firebase-sending-e-mail-1f2631d1022e
  fetch(`https://script.google.com/macros/s/AKfycbyOQLTqMUdSeBMb5FXluCxy6knMj0ng-21Pgd2zDj6mcvxqqFOUYBw5k1pHV8OuxAOZ/exec?name=${user.name}&email=${user.email}`, { 
    method: 'POST'
  })
  .then((response) => {
    console.log(response);
  },
  (error) => {
    Alert.error(`Not able to send email to the Good Humans at the moment. Error: ${error.message}`, 5000);
  });
}

export const logout = () => {
  if(!window.confirm("Logging out means you'll have to re-authenticate your email address if you're not a Google user. Are you sure you want to logout?")) return;
  auth.signOut()
  .then(() => {
    // Was previously doing these 2 things, not sure if they're necessary
    //this.setState({ user: null });
    //this.authChange();
  });
}


export default firebase;
