import React from 'react';
import { imagebankRef } from './../firebase.js';
import { Modal, Table, Button, Alert, SelectPicker, Icon, IconButton } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';

const { Column, HeaderCell, Cell } = Table;

const EditCell = ({ rowData, dataKey, onChange, ...props }) => {
  const selectData = [{
    label: 'Unauthorised',
    value: 'unauthorised'
  },{
    label: 'Viewer',
    value: 'viewer'
  },{
    label: 'Admin',
    value: 'admin'
  }]
  const handleChange = (value) => {
    if(onChange) onChange(rowData.uid, dataKey, value);
  }
  return (
    <Cell {...props} className=''>
      <SelectPicker
        cleanable={false}
        style={{marginTop: '-7px' }}
        value={rowData[dataKey]}
        onChange={handleChange}
        data={selectData}
      />
    </Cell>
  );
};

const DeleteCell = ({ rowData, dataKey, handleDelete, ...props }) => {
  return (
    <Cell {...props} style={{ padding: '7px 10px' }}>
      <IconButton
        size="sm"
        onClick={() => handleDelete(rowData)}
        icon={<Icon icon="trash-o" />}
      />
    </Cell>
  );
};

export default class UserManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      isFetching: false,
      show: false,
      isSaving: false,
      sortColumn: 'last_login',
      sortType: 'desc'
    };
    this.handleSortColumn = this.handleSortColumn.bind(this);
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
    this.onEntered = this.onEntered.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    this.fetchUsers();
  }

  fetchUsers() {
    this.setState({...this.state, isFetching: true});
    imagebankRef.child(`users/`).once("value", snapshot => {
      if (snapshot.exists()) {
        const usersObj = snapshot.val();
        const usersArray = Object.values(usersObj);
        const options = { year: "numeric", month: "long", day: "numeric" }
        usersArray.forEach((item) => {
          item.created_at = new Date(item.created_at).toLocaleDateString(undefined, options)
          item.last_login = new Date(item.last_login).toLocaleDateString(undefined, options)
        });
        const filteredArray = usersArray.filter(user => user.brand == this.props.parentBrand || user.role == 'admin')
        this.setState({...this.state, users: filteredArray, isFetching: false});
      } else {
        console.log('no users :(');
      }
    });
  }

  getData() {
    const { users, sortColumn, sortType } = this.state;
    if (sortColumn && sortType) {
      return users.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if(sortColumn === 'last_login' || sortColumn === 'created_at') {
          x = Date.parse(x);
          y = Date.parse(y);
        } else {
          x = x.charCodeAt();
          y = y.charCodeAt();
        }
        if (sortType === 'asc') {
          return x - y;
        } else {
          return y - x;
        }
      });
    }
    return users;
  }

  close() {
    this.setState({
      show: false
    });
  }
  open(e) {
    e.preventDefault();
    this.setState({
      show: true
    });
  }
  onEntered() {
    // to fix table height bug (didn't scroll to bottom)
    this.forceUpdate() 
  }

  handleSortColumn(sortColumn, sortType) {
    this.setState({
      sortColumn,
      sortType
    });
  }

  handleChange(uid, key, value) {
    this.setState({...this.state, isSaving: true});
    const nextData = Object.assign([], this.state.users);
    let userData = nextData.find(item => item.uid === uid);
    userData[key] = value;
    imagebankRef.child(`users/${uid}`).update(userData).then(() => {
      Alert.success('Data saved successfully.', 5000);
      this.setState({...this.state, isSaving: false});
    }).catch((error) => {
      Alert.error(`Data save issue. ${error}`, 5000);
      this.setState({...this.state, isSaving: false});
    });
  };

  handleDelete(user) {
    if (!window.confirm(`Are you sure you want to delete ${user.email} from the database?`)) return;
    this.setState({...this.state, isSaving: true});
    imagebankRef.child(`users/${user.uid}`).remove().then(() => {
      Alert.success('User removed.', 3000);
      const newUserArray = this.state.users.filter(item => item.email !== user.email)
      this.setState({...this.state, isSaving: false, users: newUserArray });
    }).catch((error) => {
      Alert.error(`Data removal issue. ${error}`, 3000);
      this.setState({...this.state, isSaving: false});
    });
  }

  render() {
    return (
      <div className="">
        <a href="#" className="d-block mt-2" onClick={this.open}>User Manager</a>

        <Modal 
          full 
          size='lg' 
          show={this.state.show} 
          onHide={this.close} 
          onEntered={this.onEntered}
          overflow={true}
          >
          <Modal.Header>
            <Modal.Title>Imagebank Users | { this.props.parentBrand }</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Table
              data={this.getData()}
              sortColumn={this.state.sortColumn}
              sortType={this.state.sortType}
              onSortColumn={this.handleSortColumn}
              loading={this.state.isSaving || this.state.isFetching}
              autoHeight
              hover={false}
            >

              <Column flexGrow={3} sortable minWidth={240}>
                <HeaderCell>Email</HeaderCell>
                <Cell dataKey="email" />
              </Column>

              <Column flexGrow={2} minWidth={160}>
                <HeaderCell>Name</HeaderCell>
                <Cell dataKey="name" />
              </Column>

              <Column flexGrow={2} sortable minWidth={160}>
                <HeaderCell>Role</HeaderCell>
                <EditCell dataKey="role" onChange={this.handleChange} />
              </Column>

              <Column flexGrow={2}  minWidth={130}>
                <HeaderCell>Brand</HeaderCell>
                <Cell dataKey="brand"  />
              </Column>

              <Column flexGrow={2} sortable minWidth={160}>
                <HeaderCell>Last Login</HeaderCell>
                <Cell dataKey="last_login" />
              </Column>

              <Column flexGrow={2} sortable minWidth={160}>
                <HeaderCell>Created At</HeaderCell>
                <Cell dataKey="created_at" />
              </Column>

              <Column minWidth={30}>
                <HeaderCell>Remove</HeaderCell>
                <DeleteCell handleDelete={this.handleDelete} />
              </Column>

            </Table>

          </Modal.Body>
          <Modal.Footer>
            {this.state.isSaving &&
              <p className="d-inline">Data saving...</p>
            }
            <Button className="ml-5" onClick={this.close} appearance="subtle">
              Close
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    )
  }
}
