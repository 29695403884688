import React from 'react';
import { auth, fbAuthChange, logout } from '../firebase.js';
import logo from './../assets/images/imagebank-logo.svg';
import 'rsuite/dist/styles/rsuite-default.css';
import UserManager from './UserManager'
import UploadArea from './UploadArea'


class AdminArea extends React.Component {
  render() {
    return (
      <div className="d-flex flex-column">
        <UserManager parentBrand={this.props.parentBrand}  />
        <UploadArea imagesURL={this.props.imagesURL} refreshImages={this.props.refreshImages} parentBrand={this.props.parentBrand} />
      </div>
    )
  }
}

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      authLoaded: false
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => this.authStateChanged(user))
  }

  async authStateChanged(user) {
    if(user) {
      const userData = await fbAuthChange(user, this.props.parentBrand);
      this.setState({ authLoaded: true, user: userData });
      this.props.authChange(userData);
    } else {
      this.props.authChange(null);
      this.setState({ authLoaded: true, user: null });
    }
  }

  render() {
    return (
      <div className="container">
        <header className="d-flex flex-wrap justify-content-between my-3 align-items-center">
          <img src={logo} alt='Good Humans ImageBank logo' className="logo order-md-2 mb-3 mb-md-0 cursor-pointer" onClick={this.props.clearSearch}></img>
          <div>
          {this.state.authLoaded &&
            <div className="mt-2">
              {this.state.user &&
                <div>
                  <button className="mr-3 mb-2 btn btn-light btn-sm d-inline-block" onClick={logout}>Log out</button> 
                  <p>Logged in as {this.state.user.name || this.state.user.displayName || this.state.user.email}</p>
                  { this.state.user.role === 'admin' &&
                    <AdminArea imagesURL={this.props.imagesURL} refreshImages={this.props.refreshImages} parentBrand={this.props.parentBrand} />
                  }
                </div>
              }
            </div>
          }
          </div>
        </header>
        <hr></hr>
      </div>
    );
  }
}
