import React from 'react';

class SingleImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      className: "unloaded",
      gridSizing: "_n",
      listSizing: "_s",
      gridURL: "",
      listURL: ""
    }
    // _s: 75x75, _q: 150x150 t: 100x?
    const DATE_OPTIONS = {  year: 'numeric', month: 'short', day: 'numeric' };
    this.properDate = new Date(props.item.dateupload*1000).toLocaleDateString('en-GB', DATE_OPTIONS);
  }
  componentDidMount() {
    let gridSizing = this.state.gridSizing;
    if(this.props.item.height_o>this.props.item.width_o) {
      gridSizing = "_m";
      this.setState({ gridSizing: "_m" }); // portrait
    } else if(this.props.item.width_o/this.props.item.height_o >= 10) {
      gridSizing = "_c";
      this.setState({ gridSizing: "_c" }); // super-landscape
    }
    if(typeof this.props.item.farm !== 'undefined') {
      this.setState({
        gridURL: `https://farm${this.props.item.farm}.staticflickr.com/${this.props.item.server}/${this.props.item.id}_${this.props.item.secret}${gridSizing}.jpg`,
        listURL: `https://farm${this.props.item.farm}.staticflickr.com/${this.props.item.server}/${this.props.item.id}_${this.props.item.secret}${this.state.listSizing}.jpg`,
      });
    } else if(this.props.item.url_o.indexOf('.gif') !== -1) {
      this.setState({
        gridURL: this.props.item.url_o,
        listURL: this.props.item.url_o
      });
    } else {
      this.setState({
        gridURL: "https://www.linkfish.eu/wp-content/uploads/2017/09/PDF-Icon.jpg",
        listURL: "https://www.linkfish.eu/wp-content/uploads/2017/09/PDF-Icon.jpg"
      });
    }
  }
  componentDidUpdate = (prevProps, prevState) => {

  }
  handleImageLoaded() {
    this.setState({ className: "loaded" });
  }
  render() {
    return (
      <div
        className={`${this.state.className} image-list__item ${this.state.gridSizing} ${this.props.item.url_o.match(/\.com.*\.(...)/)[1]}`}
        style={{/*background: "url("+imageURL+") no-repeat center center" */}}
        onClick={() => this.props.showModal(this.props.index)}
        data-index={this.props.index}
        id={this.props.item.id}>
        {this.props.item.farm !== 0 ?        
          <img
            className={`single-img ${this.props.view}`}
            src={this.props.view === 'grid' ? this.state.gridURL : this.state.listURL }
            alt={this.props.item.title}
            onLoad={this.handleImageLoaded.bind(this)} />
          :
          <video muted autoPlay loop width={300}>
            <source type="video/mp4" src={`https://www.flickr.com/photos/25964651@N03/${this.props.item.id}/play/360p/${this.props.item.secret}`}></source>
          </video>
        }
        <div className="image-list__item-text">
          <p className="image-list__item-title">
            {this.props.item.title}
          </p>
          <p className="image-list__item-date">
           {this.properDate}
          </p>
        </div>
      </div>
    );
  }
}

export default class ImageList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null
    };
  }
  update(newState) {
    this.props.onUpdate(newState);
  }

  render() {
    const error = this.state.error,
          isLoaded = this.props.isLoaded;
    if (this.state.error) {
      return <div>Error: {error.messsage}</div>;
    } else if (!isLoaded) {
      return (
        <div>
          <div className="container">
            Loading...
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="container">
            <p>Currently showing { this.props.limit } of { this.props.count } images</p>
          </div>
          <div className={this.props.viewType === 'list' ? 'container' : ''}>
            <div className={`image-list view-${this.props.viewType}`}>
              {this.props.images.map((item, i) => {
                return <SingleImage showModal={this.props.showModal} view={this.props.viewType} item={item} key={item.id+"_"+i} index={i}  />
              }, this)}
            </div>
          </div>

        </div>
      );
    }
  }

} // end of ImageList class

