import React from 'react';
import LoginUI from './Login.jsx';

export default function Unauthorised(props) {
  console.log(props)
  return (
    <div className="container">
      { props.authLoaded &&
        <div>
          { props.user?.role === 'unauthorised' ?
          <p>Hold up, a good human has to let you in.</p>
          :
          <div id="signin-area">
            <LoginUI />
          </div>
          }
        </div>
      }
    </div>
  )
}
