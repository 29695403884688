import React from 'react';
import firebase from './../firebase.js';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

export default class LoginUI extends React.Component {
  constructor(props) {
    super(props);

    const redirectURL = window.location.href.indexOf('local') !== -1 ? window.location.href : window.location.href;
    
    this.uiConfig = {
      credentialHelper: 'none',
      signInFlow: firebase.auth().isSignInWithEmailLink(window.location.href) ? 'redirect' : 'popup',
      signInSuccessUrl: redirectURL,
      signInOptions: [
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
          forceSameDevice: false,
          emailLinkSignIn: function() {
            return {
              url: redirectURL,
              handleCodeInApp: true
            }
          }
        },
        firebase.auth.GoogleAuthProvider.PROVIDER_ID
      ],
      callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: function(authResult, redirectUrl) {
          if(authResult.additionalUserInfo.isNewUser) {
            // if new user, redirect to 'signInSuccessUrl' (refresh) to force re-render
            //console.log('is new user')
            return true;
          } else {
            // if not new user, no redirect/refresh needed
            return false;
          }
        }
      }
    }
  }

  componentDidMount() {
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  }

  render() {
    return (
      <div className="d-flex flex-column">
        <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()}/>
      </div>
    )
  }
}
