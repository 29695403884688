import React from 'react';
import { Modal } from 'rsuite';

export default class ImageModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      isDownloading: false,
      selectedImage: { title: '', url_o: '' }
    };
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
    this.downloadImage = this.downloadImage.bind(this);
  }

  close() {
    this.props.handleClose();
  }
  open() {
    this.setState({ selectedImage: this.props.images[this.props.modalIndex] });
  }
  
  downloadImage() {
    this.setState({ isDownloading: true });
    let image = this.props.images[this.props.modalIndex];
    if(image.farm === 0) {
      // image is actually a video if farm is 0
      window.open("https://www.flickr.com/video_download.gne?id=" + image.id);
      return;
    }
    let xhr = new XMLHttpRequest();
    xhr.open("GET", image.url_o, true);
    xhr.responseType = "blob";
    xhr.onload = () => {
      let urlCreator = window.URL || window.webkitURL;
      let imageUrl = urlCreator.createObjectURL(xhr.response);
      let tag = document.createElement('a');
      tag.href = imageUrl;
      tag.download = image.title;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
      this.setState({ isDownloading: false });
    }
    xhr.send();
  }

  render() {
    const selectedImage = this.props.images[this.props.modalIndex];
    const role = this.props.user ? this.props.user.role : 'unauthorised';
    return (
      <Modal full show={this.props.show} onHide={this.close} onEnter={this.open} onEntered={this.opened}>
        { selectedImage &&
        <React.Fragment key={selectedImage.id}>
          <Modal.Header>
            <h5 className="modal-title"><span id="titleSpan" className="mr-3">{selectedImage.title}</span>
            {role === 'admin' &&
              <ModalEditArea handleSave={this.props.handleSave} handleDelete={this.props.handleDelete} handleClose={this.props.handleClose} />
            }
            </h5>
          </Modal.Header>
          <Modal.Body>
            <ModalImage selectedImage={selectedImage} />
          </Modal.Body>
          <div className="mt-3 d-flex justify-content-between">
            <img onClick={() => { this.props.nextImage('left')}} src="https://img.icons8.com/metro/52/000000/chevron-left.png" className={`modal-nav left-arrow ${this.props.modalIndex === 0 ? 'd-non' : ''}`} />
            <img
              onClick={this.downloadImage}
              src="https://img.icons8.com/material-sharp/48/000000/download.png"
              className="modal-download"
              alt="download icon"
              style={{ opacity: this.state.isDownloading ? ".6" : "1"}}
              disabled={this.state.isDownloading} />
            <img onClick={() => { this.props.nextImage('right')}} src="https://img.icons8.com/metro/52/000000/chevron-right.png" className={`modal-nav left-arrow ${this.props.modalIndex === this.props.images.length-1 ? 'd-non' : ''}`} />
          </div>
        </React.Fragment>
        }
      </Modal>

    );
  }
}

class ModalImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageLoading: false
    }
  }
  componentDidMount() {
    this.setState({ imageLoading: true });
  }
  handleImageLoaded() {
    this.setState({ imageLoading: false });
  }
  render() {
    const image = this.props.selectedImage;
    if(image.url_o.indexOf('.pdf') !== -1) {
      return <embed id="modalImage" src={image.url_o} key={image.url_o}  width="800px" height="600px;" />
    } else if(image.farm === 0 ) {
      return (
        <video autoPlay controls loop style={{ maxHeight: 'calc(100vh - 240px)', maxWidth: '100%' }}>
          <source type="video/mp4" src={`https://www.flickr.com/photos/25964651@N03/${image.id}/play/orig/${image.url_o.match(/_(.*)_o.jpg$/)[1]}`}></source>
        </video>
      )
    } else {
      return (
        <img
          src={image.url_o}
          onLoad={this.handleImageLoaded.bind(this)}
          className="modal-mainimage"
          id="modalImage"
          alt={image.title}
          style={{ opacity: this.state.imageLoading ? 0 : 1 }}
        />
      )
    }
  }
}

class ModalEditArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
    };
    this.startEditing = this.startEditing.bind(this);
    this.finishEditing = this.finishEditing.bind(this);
    this.trashImage = this.trashImage.bind(this);
  }
  startEditing() {
    let editSpan = document.getElementById("titleSpan");
    editSpan.contentEditable = "plaintext-only";
    this.setState({ isEditing: true });
    setTimeout(function() {
      editSpan.focus();
    }, 0);
  }
  finishEditing() {
    let editSpan = document.getElementById("titleSpan");
    editSpan.contentEditable = "false";
    this.props.handleSave(editSpan.innerHTML);
    this.setState({ isEditing: false });
  }
  trashImage() {
    const r = window.confirm("Do you really want to delete this image from the Image Bank?");
    if (r === true) {
      this.props.handleDelete();
      this.props.handleClose();
    }
  }

  render() {
    const { isEditing } = this.state;
    return (
      <div className="d-inline-block">
      {isEditing ? (
       <button className="btn btn-sm btn-outline-dark" onClick={this.finishEditing}>Done</button>
      ) : (
        <div className="d-inline-block">
          <svg onClick={this.startEditing} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit">
            <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
            <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
          </svg>
          <svg onClick={this.trashImage} className="bi bi-trash ml-3" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
            <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
          </svg>
        </div>
      )}
      </div>
    );
  }

}
