import React, {useEffect, useState} from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import SearchForm from './components/SearchForm.jsx'
import Header from './components/Header.jsx'
import ImageModal from './components/ImageModal'
import ImageList from './components/ImageList'
import Unauthorised from './components/Unauthorised';
import Loading  from './components/Loading.jsx';
import LogRocket from './logRocket.js';

const imagesURL = window.location.href.indexOf('localhostt') !== -1 ? "http://localhost:8888/.netlify/functions" : "https://gh-imagebank.netlify.app/.netlify/functions";
let page = 1;

function ImageBank(props) {
  let params = useParams();
  let location = useLocation();
  const [parentBrand, setParentBrand] = useState(params.id);
  const [error, setError] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [images, setImages] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [viewType, setViewType] = useState("grid");
  const [modalShow, setModalShow] = useState(false);
  const [modalIndex, setModalIndex] = useState();
  const [user, setUser] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [authLoaded, setAuthLoaded] = useState(false);
  const [limit, setLimit] = useState(false);
  const [search, setSearch] = useState({
    country: "",
    term: "",
    brand: "",
    dates: [],
    term: "",
    filters: {
      los: false,
      ren: false
    }
  });
  const navigate = useNavigate();


  const getImages = (e) => {
    setIsFetching(true); 
    setSearch({
        country: "",
        term: "",
        brand: "",
        dates: [],
        filters: {
          los: false,
          ren: false
        }
    })
    fetch(`${imagesURL}/getImages?p=0&parent=${parentBrand}`)
      .then(res => res.json())
      .then((response) => {
          setIsLoaded(true);
          setIsFetching(false);
          setImages(response.images);
          setLimit(response.images.length === response.count ? response.count : response.images.length);
          setTotalCount(response.count);
      },
      (error) => {
        setIsLoaded(true);
        setIsFetching(false);
        setError(error);
      }
    )
  }

  const handleSearch = (search) => {
    setSearch(search);
    setIsFetching(true);
    page = 1;
    fetch(`${imagesURL}/getImages?p=${page}&parent=${parentBrand}&search=${search.term}&country=${search.country}&brand=${search.brand}&dates=${search.dates}&los=${search.filters.los}&ren=${search.filters.ren}`)
      .then(res => res.json())
      .then((response) => {
        setIsLoaded(true);
        setIsFetching(false);
        setImages(response.images);
        setLimit(response.images.length === response.count ? response.count : response.images.length);
        setTotalCount(response.count);
      },
      (error) => {
        setIsFetching(false);
        setError(error);
      }
    )
  }


  const handleOnDocumentBottom = () => {
    if(isFetching || totalCount === images.length) return;
    getMoreImages();
  }
  /* This will trigger handleOnDocumentBottom when the body of the page hits the bottom */
  useBottomScrollListener(handleOnDocumentBottom, { debounce: 1000 });

  const getMoreImages = (e) => {
    if(isFetching) return;
    setIsFetching(true);
    page++;
    fetch(`${imagesURL}/getImages?p=${page}&parent=${parentBrand}&search=${search.term}&country=${search.country}&brand=${search.brand}&dates=${search.dates}&los=${search.filters.los}&ren=${search.filters.ren}`)
      .then(res => res.json())
      .then((response) => {
        setImages([
          ...images,
          ...response.images
        ]);
        setLimit(images.length + response.images.length === response.count ? response.count : images.length + response.images.length);
        setTimeout(() => {
          // give .5s to let the images load
          setIsFetching(false);
        }, 1500)
      },
      (error) => {
        console.log(error);
        setIsFetching(false);
        setError(error);
      }
    )
  }

  const saveImage = (imageTitle) => {
    let currentImage = images[modalIndex];
    currentImage.title = imageTitle;
    fetch(`${imagesURL}/saveImage`, {
      method: 'POST',
      body: JSON.stringify(currentImage),
    })
      .then(res => res.json())
      .then((response) => {
        console.log('Save success for image!', response.image);
      },
      (error) => {
        alert("Not able to save image at the moment. " + error.message);
        setError(error);
      }
    )
  }

  const deleteImage = (e) => {
    let currentImage = images[modalIndex];
    fetch(`${imagesURL}/deleteImage`, {
      method: 'POST',
      body: JSON.stringify(currentImage),
    })
      .then(res => res.json())
      .then((response) => {
        console.log('Delete success for image!', response.image);
        setImages(images.filter(obj => obj.id !== currentImage.id));
      },
      (error) => {
        alert("Not able to delete image at the moment. " + error.message);
        setError(error);
      }
    )
  }

  const showModal = (index) => {
    setModalShow(true);
    setModalIndex(index) ;
  };

  const nextImage = (direction) => {
    let nextModalIndex = direction === "left" ? modalIndex-1 : modalIndex+1;
    if(nextModalIndex === images.length) nextModalIndex = 0;
    if(nextModalIndex === -1) nextModalIndex = images.length-1;
    setModalIndex(nextModalIndex);
  }

  const hideModal = () => {
    setModalShow(false);
  };

  const handleAuthChange = (user) => {
    // user is either our firebase user data or null
    setUser(user);
    //console.log(user);
    setAuthLoaded(true);
    page = 1;
    if(user) {
      LogRocket.identify(user.uid, {
        name: user.name,
        email: user.email,
        role: user.role
      });
    }
    if(user?.role === 'admin' || (user?.role === 'viewer' && user?.brand === parentBrand)) {
      getImages();
    }
    if(user?.role === 'viewer' && user?.brand !== parentBrand) { 
      setParentBrand(user?.brand);
      navigate(`/${user?.brand}`, { state: { user } });
    }
  };

  const handleViewChange = (viewType) => {
    setViewType(viewType);
  }

  useEffect(()=> {
    // component did mount
    // if we have been redirected, get the previous state and set the user
    if(location.state?.user) handleAuthChange(location.state?.user);
    console.log(location, user);
    return () => {
      // component unmounted
    }
  },[location, user])

  return (
    <div>
      <Header
        user={user}
        authChange={handleAuthChange}
        imagesURL={imagesURL}
        refreshImages={getImages}
        clearSearch={getImages}
        parentBrand={parentBrand}
      />
      { (user?.role === 'admin' || (user?.role === 'viewer' && user?.brand === parentBrand)) &&
        <div>
          <div className="container">
            <SearchForm
              search={search}
              doSearch={handleSearch}
              clearSearch={() => getImages()}
              handleViewChange={handleViewChange}
              disabled={images.length===0}
              user={user}
              parentBrand={parentBrand}
              isFetchingImages={isFetching}
            />
          </div>
          <ImageList
            images={images}
            count={totalCount}
            isLoaded={isLoaded}
            limit={limit}
            showModal={showModal}
            viewType={viewType}
          />
          <Loading
            isFetching={isFetching}
            isLoaded={isLoaded}
            imagesToGet={images.length < totalCount}
          />
          {images.length < totalCount &&
            <p id="loadmore-text" className={`text-center text-link`} onClick={() => getMoreImages()}>Click here to load more images</p>
          }
          <ImageModal
            show={modalShow}
            handleClose={hideModal}
            handleSave={saveImage}
            handleDelete={deleteImage}
            modalIndex={modalIndex}
            images={images}
            user={user}
            nextImage={nextImage}
          />
        </div>
      }
      { (user?.role === "unauthorised"  || !user) &&
        <Unauthorised authLoaded={authLoaded} user={user} />
      }
    </div>
  );
}

export default ImageBank;
