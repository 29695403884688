import React from 'react';
import firebase, { storageRef } from './../firebase.js';


export default class UploadArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      filename: '',
      uploadShown: false,
      uploading: false,
      uploadStatus: '',
      uploadProgress: 0
    }
    this.toggleUploadArea = this.toggleUploadArea.bind(this);
  }

  componentDidMount() {
  }

  toggleUploadArea(e) {
    console.log('in upload area func')
    e.preventDefault();
    let uploadShown = this.state.uploadShown ? false : true;
    this.setState({ uploadShown, uploadStatus: '' });
  }

  onChangeHandler = event => {
    if(event.target.files.length === 0) return;
    let file = event.target.files[0];
    let filename = file.name.split('.')[0];
    this.setState({ file, filename });
  }

  handleUpload = event => {
    if(document.getElementById('upload-form').reportValidity() === false) return;
    this.setState({ uploading: true });
    const File = this.state.file;
    const filename = this.state.filename;
    const now = new Date();
    const fileRef = storageRef.child(`${now.getFullYear()}/${now.getMonth()+1}/${now.getDate()}/${File.name}`);
    var uploadTask = fileRef.put(File);
    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        let uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 95;
        this.setState({ uploadProgress });
      }, (error) => {
        this.handleUploadError(error);
        console.log('Upload error');
      }, () => {
        // upload success
        this.handleUploadSuccess(fileRef, filename);
      }
    );
  }

  handleUploadError = error => {
    this.setState({ uploading: false, uploadStatus: 'invalid' });
  }

  handleUploadSuccess = (fileRef, filename) => {
    this.setState({ uploadStatus: 'valid' });
    let customMetadata = {
      'title': filename,
      'id': 'ib-' + new Date().getTime() + Math.round(Math.random()*1000), // use time and random number so it's defo unique
      'dateupload': Math.round(new Date().getTime()/1000),
      'brand': this.props.parentBrand,
    }
    // get the download URL of the file, we gotta call firebase again
    fileRef.getDownloadURL().then((downloadURL) => {
      customMetadata.url_o = downloadURL;
      if(downloadURL.indexOf('.gif') !== -1) {
        // once we get the url, we can find the image dimensions and the load function will run
        // create a image object to get the height and width later
        const img = new Image();
        img.src = downloadURL;
        img.addEventListener("load", () => {
          let merged = {
            'height_o': img.naturalHeight,
            'width_o': img.naturalWidth,
            ...customMetadata
          };
          this.updateMetadata(merged, fileRef);
        });
      } else {
        // pdf uploaded
        this.updateMetadata(customMetadata, fileRef);
      }
    });
  }

  updateMetadata = (customMetadata, fileRef) => {
    const metadata = {
      customMetadata: customMetadata
    }
    // send message to server with image data
    this.saveImage(metadata.customMetadata);
    this.setState({ uploadProgress: 100 });
    // also update the file on firebase storage to match
    fileRef.updateMetadata(metadata).then((updatedMetadata) => {
      // Updated metadata for our uploaded file is returned in the Promise
      console.log('Metadata success: ', updatedMetadata);
      this.setState({ uploading: false });
    }).catch(function(error) {
      // Uh-oh, an error occurred!
      console.log('Metadata fail', error);
    });
  }

  saveImage = imgData => {
    //console.log(imgData);
    document.getElementById('file-input').value = null;
    this.setState({
      file: null,
      filename: '',
      uploadProgress: 0
    });
    fetch(`${this.props.imagesURL}/addImage`, {
      method: 'POST',
      body: JSON.stringify(imgData),
    })
      .then(res => res.json())
      .then((response) => {
        console.log('Save success for image!', response.image);
        this.props.refreshImages();
      },
      (error) => {
        alert("Not able to save image at the moment. " + error.message);
        this.setState({ error })
      }
    )
  }


  handleTextChange = event => {
    this.setState({filename: event.target.value});
  }

  render() {
    return (
      <div>
      {this.state.uploadShown ?
        <div>
          <a href="#" className="d-block mb-2 mt-2" onClick={this.toggleUploadArea}>Nah, cancel that.</a>
          <div className="w-100"></div>
          <form id="upload-form">
            <input id="file-input" type="file" name="file" className="" accept=".gif,.pdf" onChange={this.onChangeHandler} />
            { this.state.file &&
              <div>
                <input type="text" id="display-name" className={`form-control form-control-sm mt-2 is-${this.state.uploadStatus}`} value={this.state.filename} disabled={this.state.uploading} placeholder="File display name" onChange={this.handleTextChange} required />
                <div className="progress" style={{ height: '3px' }}>
                  <div className="progress-bar" role="progressbar" style={{ width: this.state.uploadProgress+'%' }} aria-valuenow={this.state.uploadProgress} aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div className="valid-feedback">Upload success! <span className="text-muted">Just finishing up...</span></div>
                <div className="invalid-feedback">Upload error! </div>
                <button className="btn btn-primary btn-sm mt-2" type="button" disabled={this.state.uploading} onClick={this.handleUpload}>Upload</button>
              </div>
            }
          </form>
        </div>
        :
        <a href="#" className="d-block mt-2" onClick={this.toggleUploadArea}>Want to upload?</a>
      }
      </div>
    )
  }
}
