import React from 'react';
import { render } from "react-dom";
import { 
  BrowserRouter,
  Routes,
  Route 
} from "react-router-dom";
import './index.css';
import App from './App';
import ImageBank from './ImageBank';
import * as serviceWorker from './serviceWorker';

const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
        <Route path=":id" element={<ImageBank />} />
    </Routes>
  </BrowserRouter>,
  rootElement
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
