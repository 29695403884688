import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { Alert, Button, InputPicker } from 'rsuite';
import { storageRef } from '../../firebase';

const NewBrandForm = ({ addBrand, brands = [] }) => {
  let params = useParams();
  const parentBrand = params.id;
  const [parent, setParent] = useState('');
  const [filename, setFilename] = useState('Logo');
  
  const brandFormSubmit = (e) => {
    e.preventDefault();
    Alert.info('Brand save in progress', 2000);
    document.getElementById('newbrandFieldset').disabled = true;
    const File = e.target.logo.files[0];
    let brandObj = {
      name: e.target.name.value,
      label: e.target.label.value,
      hidden: e.target.hidden.checked ? 'true' : '',
      parent: parent
    }
    console.log(brandObj);
    const fileRef = storageRef.child(`brands_2022/${File.name}`);
    fileRef.put(File).then(function(snapshot) {
      console.log('Uploaded a blob or file!');
      snapshot.ref.getDownloadURL().then((downloadURL) => {
        brandObj.logo = downloadURL;
        console.log(brandObj);
        addBrand(brandObj);
        document.getElementById('newbrandFieldset').disabled = false;
      })
    });
    
  }

  const logoUpload = (e) => {
    //get the file name
    const fileName = e.target.files[0].name;
    //replace the "Choose a file" label
    setFilename(fileName);
  }

  const formatBrands = (brands) => {
    const formattedBrands = {}; 
    brands.map(e => {
      if(e.parent == "") formattedBrands[e.label] = { 'label': e.name, 'value': e.label };
    })
    return Object.values(formattedBrands);
  }


  return (
    <form id="brandForm" onSubmit={brandFormSubmit} className="mx-auto text-left border" style={{width: '340px', padding: '20px', backgroundColor: 'white'}}>
      <p><strong>Add New Brand</strong></p>
      <fieldset id="newbrandFieldset" className="form ">
        <div className={`form-group custom-file mb-3 ${!parentBrand ? 'w-100' : ''}`}>
          <input className="custom-file-input" id="formlogo" name="logo" type="file" required onChange={logoUpload} />
          <label htmlFor="formlogo" className="custom-file-label">{filename}</label>
        </div>
        <div className="form-group">
          <label htmlFor="formname">Name</label>
          <input className="form-control" id="formname" type="text" required placeholder="Monster Fresh Brand" name="name"  />
        </div>
        <div className="form-group">
          <label htmlFor="formlabel">Label</label>
          <input className="form-control" id="formlabel" type="text" required placeholder="MFB" name="label"  />
        </div>
        <div className="form-group text-left">
          { parentBrand ? 
            <input className="form-control" type="hidden" value={parentBrand} required placeholder="Parent" name="parent" />
          : (
            <>
              <p>Parent</p>
              <InputPicker data={ formatBrands(brands) } placeholder="Parent Brand" block size="lg" onChange={val => setParent(val)} value={parent}  />
            </>
            )
          }
          
        </div>
        <div className="form-check mb-3">
          <input className="form-check-input" type="checkbox" name="hidden" value="true" id="hiddenCheck" />
          <label className="form-check-label" htmlFor="hiddenCheck">
            Hidden
          </label>
        </div>
        <Button type="submit" size="lg">Save</Button>
      </fieldset>
    </form>
  )
}

export default NewBrandForm;